import React, { useState } from 'react';
import { Box } from 'rebass/styled-components';
import { useNewsesQuery } from '../queries/useNewsesQuery';
import { PageHeading, NewsGrid } from '../components';
import Layout from '../components/Layout';
import '../styles/Map.css';
import { FaChevronDown } from "react-icons/fa";
import {graphql} from 'gatsby';
import Seo from 'gatsby-plugin-wpgraphql-seo';

const NewsPage = (pageContext: any) => {
  const allWpNews = pageContext.data.allWpNews;
  const [classify, setClassify] = useState('0');
  const [classifyText, setClassifyText] = useState('全部報導');
  const newses = useNewsesQuery(0, classify);

  const clickDropDown = () => {
    let menuContent : any = document.querySelector('.dropdown-content');
    if ( menuContent === null || menuContent.style === null) {
      return
    }
    
    if ( menuContent.style.display === 'none' ) {
      menuContent.style.display = 'block';
    }
    else if (menuContent.style.display === 'block') {
      menuContent.style.display = 'none';
    }
    else {
      menuContent.style.display = 'block';
    }
  };

  return (
    <Layout>
      {allWpNews.edges.map( (edge : any) => {
        <Seo post={edge.node} />
      })}
      
      <Box variant="container">
        <PageHeading subTitle="News">媒體報導</PageHeading>
        <Box className="dropdown" mb={80} >
          <button className="dropbtn" onClick={clickDropDown}>
            {classifyText}
            <FaChevronDown className="arrow-down" />
          </button>
          <Box className="dropdown-content" >
            {[
              { value: '0', text: '全部報導'} ,
              { value: '1', text: '最新消息'},
              { value: '2', text: '醫師專訪'},
              { value: '3', text: '部落客文章'},
            ].map(({ value, text }) => (
              <a key={value} onClick={ (e) => {setClassify(value); setClassifyText(text); } }>
                {text}
              </a>
            ))}
          </Box>
        </Box>
        <NewsGrid newses={newses} />
      </Box>
    </Layout>
  );
  
};

if (typeof window !== 'undefined') {
  window.onclick = function (event : any) {
    if (!event.target.matches('.dropbtn')) {
      let menuContent : any = document.querySelector('.dropdown-content');
      if ( menuContent === null || menuContent.style === null) {
        return
      }
      menuContent.style.display = 'none';
    }
  }
}

export default NewsPage;

export const pageQuery = graphql`
  query GET_PAGE_NEWS {
    allWpNews {
      edges {
        node {
          seo {
            title
            metaDesc
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage {
              altText
              sourceUrl
              srcSet
            }
            twitterTitle
            twitterDescription
            twitterImage {
              altText
              sourceUrl
              srcSet
            }
            canonical
            cornerstone
            schema {
              articleType
              pageType
              raw
            }
          }
        }
      }
    }
  }
`;
